<template>
  <div class="col-md-12 mx-auto">
    <div class="card rounded shadow-lg">
      <div class="card-body">
        <div class="card-title text-center">
          <h1>Clases de documento</h1>
        </div>
        <br>
        <div class="row">
          <div class="col-10">
            <div class="form-group row">
              <form v-on:submit.prevent="searchItems(keywords)">
                <div class="input-group">
                  <input type="text" v-model="keywords" class="form-control" placeholder="" aria-label="Buscar por palabras claves" aria-describedby="basic-addon2">
                  <div class="input-group-append">
                    <button type="submit" class="btn btn-outline-primary">Buscar</button>
                  </div>
                </div>                
              </form>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="">
              <button v-on:click="$router.push({ name: 'CreateTpocor', params: {add: true} });" class="btn btn-primary"><i data-feather="plus-circle"></i>Crear Clase de documento</button>
            </div>
            <br>
            <div v-if="filter" class="alert alert-primary" role="alert">
              {{ keywords }}
              <v-icon v-on:click="closeSearch">mdi-filter-remove</v-icon>
            </div>
            <div class="tab-content" id="nav-tabContent">
              <div class="tab-pane fade show active" id="list-doc" role="tabpanel" aria-labelledby="list-doc-list">
                <div class="table-responsive">
                  <table class="table table-hover table-bordered">
                    <thead>
                      <tr>
                        <td></td>
                        <td>Código</td>
                        <td>Nombre</td>
                        <td>Correspondencia</td>
                        <td>Dirección</td>
                        <td>Tipo de respuesta</td>
                        <td>Orden</td>
                        <td>Hora inicio radicación</td>
                        <td>Hora final radicación</td>
                        <td>Mostrar tramitado por</td>
                        <td>Mensaje personalizado para sello</td>
                        <td>Invalidar permisos para respuestas</td>
                        <td class="text-center"></td>
                      </tr>
                    </thead>

                    <tbody>
                      <tr v-for="(item, index) in items">
                        <td class="text-center">
                          <button v-on:click="editItem(item._id)" class="btn btn-primary">Editar</button>                                              
                        </td>
                        <td>{{ item.tcr_cdgo }}</td>
                        <td>{{ item.tcr_nmbre }}</td>
                        <td align="center"><p v-if="item.tcr_corr">Si</p><p v-else>No</p></td>
                        <td align="center"><p v-if="item.tcr_direccion=='R'">Recibida</p><p v-if="item.tcr_direccion=='E'">Enviada</p><p v-if="item.tcr_direccion=='O'">Otra</p></td>
                        <td>{{ item.tcr_respuesta_nmbre }}</td>
                        <td>{{ item.tcr_orden }}</td>
                        <td>{{ item.tcr_rad_hini | formatTime }}</td>
                        <td>{{ item.tcr_rad_hfin | formatTime }}</td>
                        <td align="center"><p v-if="item.tcr_tramitado">Si</p><p v-else>No</p></td>
                        <td>{{ item.tcr_mensaje_sello }}</td>
                        <td align="center"><p v-if="item.tcr_no_vpr">Si</p><p v-else>No</p></td>
                        <td class="text-center">
                          <button class="btn btn-danger" v-on:click="deleteItem(item._id, index)">
                            <i data-feather="delete"></i>Eliminar
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="tab-pane fade" id="list-doc-archivo" role="tabpanel" aria-labelledby="list-archivo-list">...</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import { VIcon } from 'vuetify/lib';
import { formatTime } from './../../plugins/filters';

export default {
  components: {
    //VIcon
  },
  data(){
    return{
      items: [],
      keywords:'',
      search: {},
      filter: false, // Items are filtered
    }
  },
  filters: {
    formatTime
  },
  computed: {
    showSearch(){
      if (this.filter){
        return 'block';
      } else{
        return 'none';
      }
    }
  },
  mounted: function() {
    feather.replace();
  },
  created: function()
  {
    this.fetchItems();
  },
  methods: {
    fetchItems()
    {
      let uri = '/tpocors/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        this.items = response.data;
        var temp = this.items;

        for (var i = 0; i < this.items.length; i++){
          this.items[i].tcr_respuesta_nmbre = '';
          for (var j = 0; j < temp.length; j++){
            if (String(this.items[i].tcr_respuesta) == String(temp[j]._id)){
              this.items[i].tcr_respuesta_nmbre = temp[j].tcr_nmbre;
              j = temp.length;
            }
          }
        }
      });
    },
    deleteItem(id, index)
    {
      const response = confirm('Está seguro que desea eliminar?');
      if (response) {
        let uri = '/tpocors/delete/'+id;
        this.axios.get(uri)
        .then(res => {
          this.items.splice(index, 1);
        })
        .catch(err => console.log(err));
      }
      return;
    },
    searchItems(key){
      //alert(key);
      if (key != ''){
        this.message = 'Buscando clases de documento';
        let uri = '/tpocors/search';
        this.axios.post(uri, { cmp_id: String(this.$store.state.company), key: key })
        .then(response => {
          console.log(response);
          this.items = response.data;
          this.filter = true;
        })
        .catch(err => {
          console.log(err);
          this.message = '¡Error al buscar las clases de documento' + err;
        });
      }
    },
    closeSearch(){
      this.filter = false;
      this.keywords = '';
      this.fetchItems();
    },
    newSearch () {
      this.search = {};
    },
    createItem(){
      this.$router.push({ name: 'CreateTpocor', params: {add: true} });
    },
    editItem(id){
      this.$router.push({ name: 'CreateTpocor', params: {id: id, add: false} });
    },
    respuestaNombre(_id){
      //alert(_id);
      return 'Correspondencia' + _id;
      /*
      for (var i = 0; i < this.items.length; i++){
        if (String(this.items[i]._id) == String(_id)){
          //return this.items[i].tcr_nmbre;
        }
      }
      return null;
      */
    }
  }
}
</script>
